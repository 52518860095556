import React , {useEffect}from 'react'
import './Home.css'
import $ from 'jquery'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import candles from '../images/Candles.png'
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
const Home = () => {
  // useEffect(() => {
  //   $('.autoplay').slick({
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     autoplay: true,
  //     autoplaySpeed: 2000,
  //   });
  // }, []);
  return (
    <>
      <section className='container main-section'>
        <div>
          <p>New: Our AI Integration Just Landed</p>
        </div>
        <h1 className='main-text'>Discover Endless  Possibilities in the World Of Trading</h1>
        <p className='sub-text'>Step into the world of trading excellence and seize every opportunity with our advanced <br />platform, expert guidance, and strategic insights for unrivaled financial success</p>

        <div className='btns'>
          <button className="start-btn" type="submit">Start Trading</button>
          <button className="demo-btn" type="submit">Try Demo</button>
        </div>
        <div className='social-icons'>
          <FaInstagram className='icon' />
          <FaXTwitter className='icon' />
          <FaTelegramPlane className='icon' />
          <FaDiscord className='icon' />
        </div>
        <div className='candles-section'>
          <img src={candles} className='candles-box-1' />
          <img src={candles} className='candles-box-2' />
        </div>
      </section>
      {/* Carousel Section */}
      {/* <section>
        <Slider className="autoplay">
          <div>
            <div className="card" style={{ width: '18rem' }}>
              <img src="..." className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
          <div>
            <div className="card" style={{ width: '18rem' }}>
              <img src="..." className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
          <div>
            <div className="card" style={{ width: '18rem' }}>
              <img src="..." className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
          <div>
            <div className="card" style={{ width: '18rem' }}>
              <img src="..." className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
          <div>
            <div className="card" style={{ width: '18rem' }}>
              <img src="..." className="card-img-top" alt="..." />
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" className="btn btn-primary">Go somewhere</a>
              </div>
            </div>
          </div>
        </Slider>
      </section> */}
    </>
  )
}

export default Home
